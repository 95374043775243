@import '../../styles/propertySets.css';

.logo {
  @apply --marketplaceFooterLogoText;
  font-size: 42px;
  line-height: 48px;
  color: var(--marketplaceColor);
}

.logoLandingPage {
  color: var(--matterColorLight);
}

.logoMobile {
  @apply --marketplaceFooterLogoTextMobile;

  /* Layout */
  display: flex;
  align-items: center;
}

